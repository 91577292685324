import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import MyMenu from './repeats/menu.jsx';
import CreateForm from './repeats/form.jsx';
import RepeatResults from './repeats/results.jsx';
import RepeatSchedule from './repeats/schedule.jsx';

class Page extends Component {
    render() {
        return (
            <React.Fragment>
                <MyMenu></MyMenu>

                <Switch>
                    <Route exact path="/repeat" component={CreateForm} />
                    <Route path="/repeat/results" component={RepeatResults} />
                    <Route path="/repeat/schedule" component={RepeatSchedule} />
                </Switch>
            </React.Fragment>
        );
    }
}

export default Page;
