import React from 'react';
import { Input, Card, Button } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react'; // https://github.com/arfedulov/semantic-ui-calendar-react
import axios from 'axios';

class Form extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isNetworking: false,
            datas: [],
            ts: "",
            ts_val: 0,
            url: "",
        };
    }


    handleChange = (event, {name, value}) => {
        const ts_val = (name==='ts') ? (new Date(value)).getTime() : this.state.ts_val  ;

        if (this.state.hasOwnProperty(name)) {
            this.setState({
                ts_val,
                [name]: value
            });
        }
    }
    sendRequest = event => {
        const ts = this.state.ts;
        const url = this.state.url;
        if( ts.length < 2 || url.length < 5 ) {
            alert("입력을 확인해 주세요");
            return;
        }
        const params = { ts, url, ts_val: this.state.ts_val };
        const apiUrl = window.myConfing.SERVER_URL+'once/create';
        this.setState({isNetworking: true});
        axios.post(apiUrl, params).then( response => {
            const resData = response.data;
            console.log(resData);
            alert("등록되었습니다.");
        }).catch(function (error) {
            alert("오류가 발생하였습니다!");
            console.log(error);
        }).then( () => {
            this.setState({isNetworking: false});
        });
    }

    render() {
        const reqJson = {
            ts: this.state.ts,
            ts_val: this.state.ts_val,
            url: this.state.url,
        }

        return (
            <div>
                <Input name="url"
                    size='massive' fluid
                    placeholder="요청 주소"
                    value={this.state.url}
                    onChange={this.handleChange}
                />
                <br/>
                <DateTimeInput name="ts"
                    size='massive' fluid
                    placeholder="예약 시간"
                    dateTimeFormat="YYYY-MM-DD HH:mm:ss"
                    iconPosition="left"
                    popupPosition="bottom left"
                    value={this.state.ts}
                    onChange={this.handleChange}
                />
                <br/>
                <Button
                    size='big'
                    loading={this.state.isNetworking}
                    onClick={this.sendRequest}
                >
                    작업 예약하기
                </Button>
                <br/>
                <br/>
                <br/>
                <Card fluid>
                    <Card.Content header='[Debug] requset example json' />
                    <Card.Content>{ JSON.stringify(reqJson) }</Card.Content>
                </Card>

            </div>
        );
    }
}

export default Form;
