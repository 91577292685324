import React, { Component } from 'react';
// import { Grid } from 'semantic-ui-react'

class Page extends Component {
    render() {
        return (
            <div>
                <h1>Request Scheduler</h1>
                <ul>
                    <li><a href="https://reacttraining.com/react-router/web/guides/quick-start">React-Router</a></li>
                    <li><a href="https://react.semantic-ui.com/usage">Semantic-UI-react</a></li>
                    <li><a href="https://docs.nestjs.com/">nest.js</a></li>
                    <li><a href="https://typeorm.io/">typeORM</a></li>
                    <li><a href="https://github.com/request/request">Request Module</a></li>    
                </ul>
            </div>
        );
    }
}

export default Page;
