import React from 'react';
import { Table, Label, Button } from 'semantic-ui-react'
import axios from 'axios';

class Page extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isNetworking: false,
            datas: [],
        };
    }

    getDatas(){
        this.setState({isNetworking: true});
        axios.get( window.myConfing.SERVER_URL + 'once/schedule').then( response => {
            const resData = response.data; // console.log(resData);
            this.setState({
                datas: resData
            });
        }).catch(function (error) {
            alert("문제가 발생하였습니다!");
            console.log(error);
        }).then( () => {
            this.setState({isNetworking: false});
        });
    }
    // @Lifecycle
    componentDidMount(){
        this.getDatas();
    }

    handleCancelSchedule = itemId => {
        this.setState({isNetworking: true});
        axios.delete( window.myConfing.SERVER_URL + 'once/schedule', { data: {itemId} }).then( response => {
            const respData = response.data; // console.log(resData);
            if(respData.result === 1 ){
                alert("삭제되었습니다!");
                this.getDatas();
            }
        }).catch(function (error) {
            alert("문제가 발생하였습니다!");
            console.log(error);
        }).then( () => {
            this.setState({isNetworking: false});
        });
    }

    render() {
        return (
            <Table celled>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Label ribbon>#</Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell>예약시간2</Table.HeaderCell>
                        <Table.HeaderCell>URL</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{
                    this.state.datas.map( item => {
                        return (
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.id}</Table.Cell>
                                <Table.Cell title={item.ts_val}>{item.ts.substr(0,19)}</Table.Cell>
                                <Table.Cell>{item.url}</Table.Cell>
                                <Table.Cell>
                                    <Button
                                        loading={ this.state.isNetworking }
                                        onClick={ e => this.handleCancelSchedule(item.id) }
                                    >
                                        취소하기
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })
                }</Table.Body>
            </Table>
        );
    }
}

export default Page;
