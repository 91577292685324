import React from 'react';
import { Route, Switch } from "react-router-dom";
import MyMenu from './onces/menu.jsx';
import CreateForm from './onces/form.jsx';
import OnceResults from './onces/results.jsx';
import OnceSchedule from './onces/schedule.jsx';

class Page extends React.Component {
    render() {
        return (
            <React.Fragment>
                <MyMenu></MyMenu>

                <Switch>
                    <Route exact path="/once" component={CreateForm} />
                    <Route path="/once/results" component={OnceResults} />
                    <Route path="/once/schedule" component={OnceSchedule} />
                </Switch>
            </React.Fragment>
        );
    }
}

export default Page;
