import React from 'react';
import { Input, Card, Button } from 'semantic-ui-react';
import axios from 'axios';

class Form extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isNetworking: false,
            datas: [],
            name: "", url: "",
            sec: "", min: "", hour: "",
            day: "", week: "", mon: "",
        };
    }


    handleChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }
    sendRequest = event => {
        console.log("ddd");
        
        const name = this.state.name;
        if( name.length < 2 || name.length > 32 ){
            alert("작업명은 2~32자로 입력해 주세요");
            return;
        }
        const url = this.state.url;
        if( url.length < 5 ) {
            alert("요청 주소를 확인해 주세요");
            return;
        }
        let mon = this.state.mon.length<1 ? -1 : Number(this.state.mon);
        if( isNaN(mon) || mon < 0 || mon > 12 ) mon = -1;
        let week = this.state.week.length<1 ? -1 : Number(this.state.week);
        if( isNaN(week) || week < 0 || week > 7 ) week = mon===-1 ? -1 : 0 ;
        let day = this.state.day.length<1 ? -1 : Number(this.state.day);
        if( isNaN(day) || day < 0 || day > 31 ) day = week===-1 ? -1 : 0 ;
        let hour = this.state.hour.length<1 ? -1 : Number(this.state.hour);
        if( isNaN(hour) || hour < 0 || hour > 24 ) hour = day===-1 ? -1 : 0 ;
        let min = this.state.min.length<1 ? -1 : Number(this.state.min);
        if( isNaN(min) || min < 0 || min > 60 ) min = hour===-1 ? -1 : 0 ;
        let sec = this.state.sec.length<1 ? -1 : Number(this.state.sec);
        if( isNaN(sec) || sec < 0 || sec > 60 ) sec = min===-1 ? -1 : 0 ;

        const params = { name, url, sec, min, hour, day, week, mon };
        const apiUrl = window.myConfing.SERVER_URL+'repeat/create';
        this.setState({isNetworking: true});
        axios.post(apiUrl, params).then( response => {
            const resData = response.data;
            console.log(resData);
            alert("등록되었습니다.");
        }).catch(function (error) {
            console.log(error);
            alert("오류가 발생하였습니다!");
        }).then( () => {
            this.setState({isNetworking: false});
        });
    }

    render() {
        let mon = this.state.mon.length<1 ? -1 : Number(this.state.mon);
        if( isNaN(mon) || mon < 0 || mon > 12 ) mon = -1;
        let week = this.state.week.length<1 ? -1 : Number(this.state.week);
        if( isNaN(week) || week < 0 || week > 7 ) week = mon===-1 ? -1 : 0 ;
        let day = this.state.day.length<1 ? -1 : Number(this.state.day);
        if( isNaN(day) || day < 0 || day > 31 ) day = week===-1 ? -1 : 0 ;
        let hour = this.state.hour.length<1 ? -1 : Number(this.state.hour);
        if( isNaN(hour) || hour < 0 || hour > 24 ) hour = day===-1 ? -1 : 0 ;
        let min = this.state.min.length<1 ? -1 : Number(this.state.min);
        if( isNaN(min) || min < 0 || min > 60 ) min = hour===-1 ? -1 : 0 ;
        let sec = this.state.sec.length<1 ? -1 : Number(this.state.sec);
        if( isNaN(sec) || sec < 0 || sec > 60 ) sec = min===-1 ? -1 : 0 ;
        const reqJson = {
            name: this.state.name,
            url: this.state.url,
            mon, week, day, hour, min, sec,
        }

        return (
            <div>
                <Input name="name"
                    size='massive' fluid
                    placeholder="작업명"
                    value={this.state.name}
                    onChange={this.handleChange}
                />
                <br/>
                <Input name="url"
                    size='massive' fluid
                    placeholder="요청 주소"
                    value={this.state.url}
                    onChange={this.handleChange}
                />
                <br/>
                <Input name="mon" type="number"
                    size='massive'
                    placeholder="주기 월"
                    value={this.state.mon}
                    onChange={this.handleChange}
                />
                <Input name="week" type="number"
                    size='massive'
                    placeholder="주기 주(요일)"
                    value={this.state.week}
                    onChange={this.handleChange}
                />
                <Input name="day" type="number" 
                    size='massive'
                    placeholder="주기 일"
                    value={this.state.day}
                    onChange={this.handleChange}
                />
                <Input name="hour" type="number" 
                    size='massive'
                    placeholder="주기 시"
                    value={this.state.hour}
                    onChange={this.handleChange}
                />
                <Input name="min" type="number"
                    size='massive'
                    placeholder="주기 분"
                    value={this.state.min}
                    onChange={this.handleChange}
                />
                <Input name="sec"
                    size='massive' type="number"
                    placeholder="주기 초"
                    value={this.state.sec}
                    onChange={this.handleChange}
                />
                <br/>
                <br/>
                <Button
                    size='big'
                    loading={this.state.isNetworking}
                    onClick={this.sendRequest}
                >
                    작업 예약하기
                </Button>
                <br/>
                <br/>
                <br/>
                <Card fluid>
                    <Card.Content header='[Debug] requset example json' />
                    <Card.Content>{ JSON.stringify(reqJson) }</Card.Content>
                </Card>

            </div>
        );
    }
}

export default Form;
