import React from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import history from '../../../history.js';

export default class MyMenu extends React.Component {
    state = { activeItem: 'schedule' }

    handleItemClick = (e, { name }) => {
        let activeItem;
        switch (name) {
            case 'schedule': history.push('/repeat/schedule'); activeItem='schedule'; break;        
            case 'results': history.push('/repeat/results'); activeItem='results';break;
            case 'create': history.push('/repeat'); activeItem='create'; break;
            default: break;
        }
        this.setState({ activeItem });
    }

    render() {
        const { activeItem } = this.state;

        return (
            <Menu pointing secondary>
                <Menu.Item
                    name='schedule'
                    active={activeItem === 'schedule'}
                    onClick={this.handleItemClick}
                ><Icon name='ordered list'/>예정 작업</Menu.Item>
                <Menu.Item
                    name='results'
                    active={activeItem === 'results'}
                    onClick={this.handleItemClick}
                ><Icon name='history'/>작업 결과</Menu.Item>
                <Menu.Item
                    name='create'
                    active={activeItem === 'create'}
                    onClick={this.handleItemClick}
                ><Icon name='add'/>  추가하기</Menu.Item>
            </Menu>
        );
    }
}