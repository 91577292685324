import React from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import history from '../../history.js';

export default class MyMenu extends React.Component {
    constructor(props){
        super(props);
        let activeItem;
        
        switch (history.location.pathname) {
            case '/': activeItem='dashboard'; break;
            case '/once': activeItem='once'; break;
            case '/repeat': activeItem='repeat'; break;        
            default: break;
        }
        this.state = { activeItem }
    }

    handleItemClick = (e, { name }) => {
        let activeItem;
        switch (name) {
            case 'dashboard': history.push('/'); activeItem='dashboard'; break;
            case 'once': history.push('/once'); activeItem='once'; break;
            case 'repeat': history.push('/repeat/schedule'); activeItem='repeat'; break;        
            default: break;
        }
        this.setState({ activeItem });
    }

    render() {
        const { activeItem } = this.state;

        return (
            <Menu vertical fluid pointing inverted>
                <Menu.Item
                    name='dashboard'
                    active={activeItem === 'dashboard'}
                    onClick={this.handleItemClick}
                >
                    대시보드
                    <Icon name='dashboard' />
                </Menu.Item>
                <Menu.Item
                    name='once'
                    active={activeItem === 'once'}
                    onClick={this.handleItemClick}
                >
                    스케쥴링
                    <Icon name='time' />
                </Menu.Item>
                <Menu.Item
                    name='repeat'
                    active={activeItem === 'repeat'}
                    onClick={this.handleItemClick}
                >
                    반복작업
                    <Icon name='repeat' />                
                </Menu.Item>
            </Menu>
        );
    }
}
