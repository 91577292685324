import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import './App.css';
import { Grid, Segment } from 'semantic-ui-react'
import MyMenu from './layouts/menu.jsx';
import MyDashboard from './pages/dashboard.jsx';
import RSOnce from './pages/once.jsx';
import RSRepeat from './pages/repeat.jsx';
import LoginForm from './layouts/LoginLayout.jsx';

class App extends Component {
    // state = { isNotAuth: false };
    state = { isNotAuth: true };
    handleAuth = auth => {
        this.setState({isNotAuth: auth});
    }

    render() {
        return this.state.isNotAuth ? <LoginForm handleAuth={this.handleAuth}/> : (
            <Grid id="APP">
                <Grid.Column id="MENU" width={4}>
                    <MyMenu {...this.props} />
                </Grid.Column>

                <Grid.Column id="CONTENTS" stretched width={12}>
                    <Segment>
                        <Switch>
                            <Route exact path="/" component={MyDashboard} />
                            <Route path="/once" component={RSOnce} />
                            <Route path="/repeat" component={RSRepeat} />
                        </Switch>
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

export default App;

