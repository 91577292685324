import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import history from './history';
import 'semantic-ui-css/semantic.min.css'
// root compo
import App from './components/App.jsx';

window.myConfing = {
    SERVER_URL : "https://reqs.sbsoft.kr/" ,
}

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>
    , document.getElementById('root')
);
