import React from 'react';
import { Table, Label } from 'semantic-ui-react'
import axios from 'axios';

class Page extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            datas: []
        };
    }
    // @Lifecycle
    componentDidMount(){
        axios.get( window.myConfing.SERVER_URL + 'repeat/result').then( response => {
            const resData = response.data; // console.log(resData);
            this.setState({
                datas: resData
            });
        }).catch(function (error) {
            // handle error
            console.log(error);
        });
    }

    render() {
        return (
            <Table celled>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell>
                            <Label ribbon>#</Label>
                        </Table.HeaderCell>
                        <Table.HeaderCell>완료시간</Table.HeaderCell>
                        <Table.HeaderCell>작업#</Table.HeaderCell>
                        <Table.HeaderCell>result</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{
                    this.state.datas.map( item => {
                        return (
                            <Table.Row key={item.id}>
                                <Table.Cell>{item.id}</Table.Cell>
                                <Table.Cell title={item.ts_val}>
                                    {item.ts.substr(0,19)}
                                </Table.Cell>
                                <Table.Cell>{item.repeat_id}</Table.Cell>
                                <Table.Cell>{item.result}</Table.Cell>
                            </Table.Row>
                        );
                    })
                }</Table.Body>
            </Table>
        );
    }
}

export default Page;
